
import { Vue, Component } from 'vue-property-decorator'
import $app from '@/plugins/modules'
import { Reports } from '@/modules/reports'

@Component
export default class CalendarView extends Vue {
  reports = $app.module(Reports)
  loading = false
  processing = false
  from = new Date().addMonth(-1).native(false)
  till = new Date().native(false)
}
